<template>
  <div>
    <div v-if="languageSelect === 'zh'" class="footer">
      <div
        v-if="clickthis === false"
        style="width: 20%; color: #d81e06"
        @click="backHome()"
      >
        <div class="homeStyle"></div>
        <div>首页</div>
      </div>
      <div v-if="clickthis === true" style="width: 20%" @click="backHome()">
        <div class="homeStyle1"></div>
        <div>首页</div>
      </div>
      <div style="width: 20%">
        <a href="https://www.oilgascommunity.com/">
          <div class="communityStyle"></div>
          <div>社区</div>
        </a>
      </div>
      <div style="width: 20%">
        <a href="https://cn.oilgasmall.com/">
          <div class="exhindStyle"></div>
          <div>展馆</div>
        </a>
      </div>
      <div style="width: 20%" @click="tackConstruct()">
        <div class="recruitStyle"></div>
        <div>人才</div>
      </div>
      <div style="width: 20%" @click="tack()">
        <div v-if="clickthis == false" class="myStyle"></div>
        <div v-else class="myStyle1"></div>
        <div v-if="clickthis == false">我的</div>
        <div v-else style="color: #d81e06">我的</div>
      </div>
    </div>
    <div v-else class="footer">
      <div
        v-if="clickthis === false"
        style="width: 20%; color: #d81e06"
        @click="backHome()"
      >
        <div class="homeStyle"></div>
        <div>Index</div>
      </div>
      <div v-if="clickthis === true" style="width: 20%" @click="backHome()">
        <div class="homeStyle1"></div>
        <div>Index</div>
      </div>

      <div style="width: 20%">
        <a href="https://www.oilgascommunity.com/">
          <div class="communityStyle"></div>
          <div>Community</div>
        </a>
      </div>
      <div style="width: 20%">
        <a href="https://oilgasmall.com/">
          <div class="exhindStyle"></div>
          <div>Expo</div>
        </a>
      </div>
      <div style="width: 20%">
        <div class="recruitStyle"></div>
        <div>Talent</div>
      </div>
      <div style="width: 20%" @click="tack()">
        <div v-if="clickthis == false" class="myStyle"></div>
        <div v-else class="myStyle1"></div>
        <div v-if="clickthis == false">My</div>
        <div v-else style="color: #d81e06">My</div>
      </div>
    </div>
    <PClogin :dialogVisible="cancleVisible" @hide="hide"></PClogin>
    <construct
      :dialogVisible="constructVisible"
      @hide="hideConstruct"
    ></construct>
    <!--    <CancleTip-->
    <!--        :dialogVisible="cancleVisible"-->
    <!--        @hide="hide"-->
    <!--    ></CancleTip>-->
  </div>
</template>


<script>
import PClogin from "@/components/PClogin.vue";
import construct from "@/components/construct.vue";

export default {
  name: "BottomBanner",

  props: [],

  components: { PClogin, construct },

  data() {
    return {
      clickthis: false,
      languageSelect: "",
      cancleVisible: false,
      constructVisible: false,
    };
  },
  created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    if (localStorage.getItem("myclick")) {
      this.clickthis = true;
    }
  },
  methods: {
    backHome() {
      this.clickthis = false;
      this.$router.push("/");
      document.title = "油气知识-首页";
    },
    hide() {
      this.cancleVisible = false;
      document.title = "油气知识";
    },
    hideConstruct() {
      this.constructVisible = false;
      document.title = "油气知识";
    },
    tack() {
      this.clickthis = true;
      // this.cancleVisible = true;
      this.$router.push("/user")
      document.title = "我的信息";
    },
    tackConstruct() {
      this.constructVisible = true;
      document.title = "油气人才";
    },
  },

  mounted() {},
};
</script>


<style type="text/scss" lang="scss" scoped>
.homeStyle {
  width: 20px;
  height: 25px;
  margin: 0 auto;
  background-image: url("../assets/home.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}
.homeStyle1 {
  width: 20px;
  height: 25px;
  margin: 0 auto;
  background-image: url("../assets/homeFalse.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}
.communityStyle {
  width: 20px;
  height: 25px;
  margin: 0 auto;
  background-image: url("../assets/community.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}
.recruitStyle {
  width: 20px;
  height: 25px;
  margin: 0 auto;
  background-image: url("../assets/recruit.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}
.exhindStyle {
  width: 20px;
  height: 25px;
  margin: 0 auto;
  background-image: url("../assets/exhind.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}
.myStyle {
  width: 20px;
  height: 25px;
  margin: 0 auto;
  background-image: url("../assets/my.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}
.myStyle1 {
  width: 20px;
  height: 25px;
  margin: 0 auto;
  background-image: url("../assets/myTrues.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}
.footer {
  height: 55px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  color: rgba(100, 98, 98, 0.7);
  font-size: 15px;
  text-align: center;
  padding: 10px;
  font-weight: 500;
  z-index: 9999; /* 设置一个较大的 z-index 值 */
}

.nav-link {
  color: #fff;
  text-decoration: none;
  margin: 0 15px;
}
</style>
