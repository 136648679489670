<!-- eslint-disable no-sparse-arrays -->
/* eslint-disable no-unused-vars */
<template>
  <div>
    <Header></Header>
    <div style="flex: 1" v-if="isMobile === false" class="wrapper pc">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>{{ $t('member.joinMember') }}</span>
        </div>
        <div class="text item">
          <div class="user">
            <!--              头像-->
            <div>
              <span class="el-dropdown-link">
                <el-avatar
                  :size="88"
                  :src="userInfo.avatar ? baseURL + userInfo.avatar : avator"
                ></el-avatar>
              </span>
            </div>
            <!--              详细信息-->
            <div class="detail">
              <div class="title">
                {{ userInfo.username }}
              </div>
              <div class="title">
                {{ userInfo.phone }}
              </div>
            </div>
          </div>
          <!--            会员制-->
          <div class="money">
            <li v-for="(item, index) in member" :key="index">
              <div
                class="memberItem"
                :class="{ redBorder: item.payId === choicepayId }"
                @click="chose(item)"
              >
                <div class="Item">
                  <div class="span">
                    {{ item.title }}
                  </div>
                  <div
                    class="span"
                    style="font-size: 28px; color: var(--main-color)"
                  >
                    {{ item.price }}
                  </div>
                </div>
                <div class="power">
                  <span style="color: gray">{{ $t('member.memberBenefits') }}</span>
                  <div>
                    <li>
                      <div
                        style="
                          margin: 10px 0px;
                          font-size: 18px;
                          display: flex;
                          justify-content: space-between;
                        "
                      >
                        <div>{{ $t('member.dialogNum') }}</div>
                        <div class="success">{{ item.amount }}{{ $t('member.times') }}</div>
                      </div>
                    </li>
                    <li v-for="(power, index) in item.equity" :key="index">
                      <div
                        style="
                          margin: 10px 0px;
                          font-size: 18px;
                          display: flex;
                          justify-content: space-between;
                        "
                      >
                        <div>{{ power.value }}</div>
                        <div v-if="power.label == '√'" class="success icon">
                          <i class="el-icon-check"></i>
                        </div>
                        <div v-else class="error icon">
                          <i class="el-icon-close"></i>
                        </div>
                      </div>
                    </li>
                  </div>
                </div>
                <div
                  class="displayLogo"
                  :class="{ displayLogo1: item.payId === choicepayId }"
                >
                  <div class="choiceLogo"><div class="choiceIcon"></div></div>
                </div>
              </div>
            </li>
          </div>

          <!--            支付-->
          <div class="checkout">
            <div>{{ $t('member.payMethod') }}</div>
            <div style="display: flex">
              <div
                class="out"
                :class="{ outReally: weixin }"
                @click="checkweixin()"
              >
              {{ $t('member.payWechat') }}
                <div class="choiceLogo"><div class="choiceIcon"></div></div>
              </div>
              <div
                class="out"
                :class="{ outReally: zhifubao }"
                @click="checkzhifubao()"
              >
              {{ $t('member.payAlipay') }}
                <div class="choiceLogo"><div class="choiceIcon"></div></div>
              </div>
              <div
                class="out"
                :class="{ outReally: Paypal }"
                @click="checkPaypal()"
              >
              {{ $t('member.payPaypal') }}
                <div class="choiceLogo"><div class="choiceIcon"></div></div>
              </div>
            </div>
          </div>

          <!--            阅读-->
          <div class="checkbox">
            <el-checkbox v-model="pay">{{ $t('member.agreeTip') }}</el-checkbox>
            <span class="red" @click="payAgreement()"
              >{{ $t('member.agreeTip1') }}</span
            >
          </div>

          <!--            同意-->
          <div class="agree">
            <div class="agreebutton" @click="Getpay()">{{ $t('ai.activate') }}</div>
          </div>
        </div>
      </el-card>
    </div>
    <div v-else class="Mobile">
      <div class="text item">
        <div class="user">
          <div>
            <span class="el-dropdown-link">
              <el-avatar
                :size="60"
                :src="userInfo.avatar ? baseURL + userInfo.avatar : avator"
              ></el-avatar>
            </span>
          </div>
          <!--              详细信息-->
          <div class="detail">
            <div class="title">
              {{ userInfo.username }}
            </div>
            <div class="title">
              {{ userInfo.phone }}
            </div>
          </div>
        </div>
        <!--            会员制-->
        <div class="money">
          <li v-for="(item, index) in member" :key="index">
            <div
              class="memberItem"
              :class="{ redBorder: item.payId === choicepayId }"
              @click="chose(item)"
            >
              <div class="Item">
                <div class="span">
                  {{ item.title }}
                </div>
                <div
                  class="span"
                  style="font-size: 16px; color: var(--main-color)"
                >
                  {{ item.price }}
                </div>
              </div>
              <div
                class="displayLogo"
                :class="{ displayLogo1: item.payId === choicepayId }"
              >
                <div class="choiceLogo"><div class="choiceIcon"></div></div>
              </div>
            </div>
          </li>
        </div>
        <!-- 权益信息 -->
        <div
          class="power"
          v-for="(item, index) in member"
          :key="index"
          :class="{ redBorder: item.payId === choicepayId }"
        >
          <div class="title">{{ item.title }}{{ $t('member.privilegeTip') }}</div>
          <table
            id="tab01"
            border="1px solid #ccc"
            cellspacing="0"
            cellpadding="0"
          >
            <tr>
              <td>{{ $t('member.privilege') }}</td>
              <td>{{ $t('member.interests') }}</td>
            </tr>
            <tr>
              <td>{{ $t('member.dialogNum') }}</td>
              <td>{{ item.amount }}{{ $t('member.times') }}</td>
            </tr>
            <tr v-for="(power, index) in item.equity" :key="index">
              <td>{{ power.value }}</td>
              <td>
                <span v-if="power.label == '√'" class="success icon">
                  <i class="el-icon-check"></i>
                </span>
                <span v-else class="error icon">
                  <i class="el-icon-close"></i>
                </span>
              </td>
            </tr>
          </table>
        </div>
        <!--            支付-->
        <div class="checkout">
          <div class="title">{{ $t('member.times') }}</div>
          <div>
            <div class="list">
              <div
                class="item"
                :class="{ choose: weixin }"
                @click="checkweixin()"
              >
                <div class="left">
                  <img src="@/assets/weixinPay.png" alt="" />
                  {{ $t('member.payWechat') }}
                </div>
                <div class="right">
                  <img src="@/assets/notcheck.png" alt="" class="checkImg" />
                  <img src="@/assets/doCheck.png" alt="" class="doCheck" />
                </div>
              </div>
              <div
                class="item"
                :class="{ choose: zhifubao }"
                @click="checkzhifubao()"
              >
                <!-- v-else -->
                <div class="left">
                  <img src="@/assets/zhifubaoPay.png" alt="" />
                  {{ $t('member.payAlipay') }}
                </div>
                <div class="right">
                  <img src="@/assets/notcheck.png" alt="" class="checkImg" />
                  <img src="@/assets/doCheck.png" alt="" class="doCheck" />
                </div>
              </div>
              <div
                class="item"
                :class="{ choose: Paypal }"
                @click="checkPaypal()"
              >
                <!-- v-else -->
                <div class="left">
                  <img src="@/assets/paypal.png" alt="" />
                  {{ $t('member.payPaypal') }}
                </div>
                <div class="right">
                  <img src="@/assets/notcheck.png" alt="" class="checkImg" />
                  <img src="@/assets/doCheck.png" alt="" class="doCheck" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--            阅读-->
        <div class="checkbox">
          <el-checkbox v-model="pay">{{ $t('member.agreeTip') }}</el-checkbox>
          <span class="red" @click="payAgreement()"
            >{{ $t('member.agreeTip1') }}</span
          >
        </div>

        <!--            同意-->
        <div class="agree">
          <div class="agreebutton" @click="Getpay()">{{ $t('ai.activate') }}</div>
        </div>
      </div>
    </div>

    <el-dialog
      :title="$t('member.weChatPay')"
      :visible.sync="isWechatCodeShow"
      width="500px"
      center
      class="payWechat"
      v-if="isMobile === false"
      :close="closeWechat"
    >
      <div class="flex">
        <div
          ref="wechatcode"
          id="wechatcode"
          v-loading="loading"
          element-loading-text="..."
          element-loading-spinner="el-icon-loading"
        ></div>
        <div class="right">
          <div class="money">
            {{ $t('member.paymentAmount') }}：<span>{{ money }}</span>
          </div>
          <div class="text">
            <img src="@/assets/saoma.png" alt="" />
            {{ $t('member.wechatScanTip') }}
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title=""
      :show-close="false"
      :visible.sync="payDialog"
      width="80%"
      center
    >
      <div class="content paymentCon" v-html="payment"></div>
      <div slot="footer" class="dialog-footer">
        <div class="agreebutton" @click="agreePay()">{{ $t('common.confirm') }}</div>
      </div>
    </el-dialog>
    <div style="margin-top: auto">
      <Footer v-if="isMobile === false"></Footer>
    </div>
  </div>
</template>


<script>
import {
  memberlist,
  orderqrCode,
  orderaliCode,
  payPalOrder,
  agreement,
  getOrderBycode,
} from "@/api/user";
import QRCode from "qrcodejs2"; // 引入qrcode
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "member",

  props: [],

  components: { Footer, Header },

  data() {
    return {
      isWechat: false,
      out_trade_no: null,
      isMobile: false,
      payDialog: false,
      pay: false,
      payment: "",
      baseURL: this.$utils.baseURL,
      avator: require("@/assets/avator.png"),
      userInfo: this.$store.state.user,
      weixin: true,
      zhifubao: false,
      Paypal: false,
      choicepayId: null,
      money: "",
      loading: false,
      isWechatCodeShow: false,
      member: [
        {
          choice: 1,
          item: "普通会员",
          money: "￥9.90",
          power: [
            {
              name: "对话次数",
              count: "200",
            },
            {
              name: "期刊文献下载量",
              count: "200",
            },
            {
              name: "数据服务",
              count: "200",
            },
          ],
        },
        {
          choice: 0,
          item: "超级会员",
          money: "￥29.90",
          power: [
            {
              name: "对话次数",
              count: "200",
            },
            {
              name: "期刊文献下载量",
              count: "200",
            },
            {
              name: "数据服务",
              count: "200",
            },
          ],
        },
        {
          choice: 0,
          item: "至尊会员",
          money: "￥39.90",
          power: [
            {
              name: "对话次数",
              count: "200",
            },
            {
              name: "期刊文献下载量",
              count: "200",
            },
            {
              name: "数据服务",
              count: "200",
            },
          ],
        },
      ],
      weChatParameter: {},
      code: null,
    };
  },

  methods: {
    Getagreement() {
      let id = this.$i18n.locale == 'zh' ? 6 : 7
      agreement(id).then((res) => {
        this.payment = res.data.content;
      });
    },
    // 获取付费会员列表
    getmemberlist() {
      memberlist().then((res) => {
        res.rows.forEach((item) => {
          item.equity = JSON.parse(item.equity);
          item.title = item.title.includes('青铜') ? this.$t('member.memberLevel1') : (item.title.includes('白金') ? this.$t('member.memberLevel2') : this.$t('member.memberLevel3'))
        });
        this.member = res.rows;
        this.choicepayId = this.member[0].payId;
        this.money = this.member[0].price;
      });
    },
    agreePay() {
      this.payDialog = false;
      this.pay = true;
    },
    payAgreement() {
      this.payDialog = true;
    },
    chose(item) {
      this.choicepayId = item.payId;
      this.money = item.price;
    },
    checkweixin() {
      this.weixin = true;
      this.zhifubao = false;
      this.Paypal = false;
    },
    checkzhifubao() {
      this.weixin = false;
      this.zhifubao = true;
      this.Paypal = false;
    },
    checkPaypal() {
      this.weixin = false;
      this.zhifubao = false;
      this.Paypal = true;
    },
    Getpay() {
      if (this.pay == false) {
        this.$message.error("请阅读并接受《油气知识用户购买协议》");
        return false;
      }
      if (!this.isMobile) {
        if (this.weixin) {
          this.loading = true;
          this.isWechatCodeShow = true;
          let data = {
            payId: this.choicepayId,
            type: 1,
          };
          orderqrCode(data).then((res) => {
            this.wechatPayUrl = res.data.QrCode;
            this.out_trade_no = res.data.out_trade_no;
            this.delQrcode();
            // eslint-disable-next-line no-unused-vars
            const wechatcode = new QRCode("wechatcode", {
              width: 170,
              height: 170,
              text: this.wechatPayUrl, // 二维码地址
              colorDark: "#000",
              colorLight: "#fff",
            });
            this.loading = false;
            this.interval = window.setInterval(() => {
              setTimeout(this.getOrderStatus(), 0);
            }, 3000);
          });
        }
        if (this.zhifubao) {
          let data = {
            payId: this.choicepayId,
            type: 1,
          };
          orderaliCode(data).then((res) => {
            if (res.code === 200) {
              var form = res.data.data;
              const div = document.createElement("div");
              div.innerHTML = form; //此处form就是后台返回接收到的数据
              document.body.appendChild(div);
              document.forms[0].submit();
            } else {
              return this.$message.error(res.data.msg);
            }
          });
        }
        if (this.Paypal) {
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
          });
          payPalOrder({
            payId: this.choicepayId,
            type: 1,
          })
            .then((res) => {
              if (res.code === 200) {
                let url = res.data;
                loading.close();
                window.location.replace(url);
              } else {
                loading.close();
                return this.$message.error(res.data.msg);
              }
            })
            .catch(() => {
              loading.close();
            });
        }
      } else {
        if (this.weixin) {
          this.loading = true;
          this.isWechatCodeShow = true;
          if (!this.isWechat) {
            let data = {
              payId: this.choicepayId,
              type: 2,
            };
            orderqrCode(data).then((res) => {
              if (res.code == 200) {
                let url = res.data.mweb_url;
                window.location.replace(url);
              } else {
                return this.$message.error(res.data.msg);
              }
            });
          } else {
            let data = {
              payId: this.choicepayId,
              type: 3,
              code: this.code,
            };
            orderqrCode(data).then((res) => {
              if (res.code == 200) {
                this.weChatParameter = res.data;
                this.weixinPay();
              } else {
                return this.$message.error(res.data.msg);
              }
            });
          }
        }
        if (this.zhifubao) {
          let data = {
            payId: this.choicepayId,
            type: 2,
          };
          orderaliCode(data).then((res) => {
            if (res.code === 200) {
              var form = res.data.data;
              const div = document.createElement("div");
              div.innerHTML = form; //此处form就是后台返回接收到的数据
              document.body.appendChild(div);
              document.forms[0].submit();
              //return this.$router.push('/aliPay')
            } else {
              return this.$message.error(res.data.msg);
            }
          });
        }
        if (this.Paypal) {
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
          });
          payPalOrder({
            payId: this.choicepayId,
            type: 2,
          })
            .then((res) => {
              if (res.code === 200) {
                let url = res.data;
                loading.close();
                window.location.replace(url);
              } else {
                loading.close();
                return this.$message.error(res.data.msg);
              }
            })
            .catch(() => {
              loading.close();
            });
        }
      }
    },
    getCodeApi() {
      let href = window.location.href;
      let appid = "wx7e4178a278dd6ca7"; //公众号的APPID
      let code = this.getUrlParam("code"); //检查url中是否存在code

      let local = href + "/#/promote"; //测试路径，即当前h5页面的路径
      if (code == null || code === "") {
        //不存在就打开下面的地址进行授权
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
          local
        )}&response_type=code&scope=snsapi_base&state=1#wechat_redirect`;
      } else {
        this.code = code;
      }
    },
    getUrlParam(name) {
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");

      const r = window.location.search.substr(1).match(reg);

      if (r != null) {
        return unescape(r[2]);
      }

      return null;
    },
    urlencode(str) {
      str = (str + "").toString();
      return encodeURIComponent(str)
        .replace(/!/g, "%21")
        .replace(/'/g, "%27")
        .replace(/\(/g, "%28")
        .replace(/\)/g, "%29")
        .replace(/\*/g, "%2A")
        .replace(/%20/g, "+");
    },
    getUrlKey(name) {
      //获取url 参数
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
            // eslint-disable-next-line no-sparse-arrays
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    // 清空二维码,避免生成多个二维码
    delQrcode() {
      this.$refs.wechatcode.innerHTML = "";
    },
    closeWechat() {
      this.beforeDestroy();
      this.out_trade_no = null;
    },
    beforeDestroy() {
      clearInterval(this.interval);
      this.interval = null;
    },
    getOrderStatus() {
      getOrderBycode(this.out_trade_no).then((res) => {
        if (res.data.status === 1) {
          this.beforeDestroy();
          this.$message({
            message: "支付成功", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              this.$router.push("/orderList");
            },
          });
        }
      });
    },
    is_weixn() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },

    //解决微信内置对象报错
    weixinPay(data) {
      var vm = this;
      if (typeof window.WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            vm.onBridgeReady(data),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", vm.onBridgeReady(data));
          document.attachEvent("onWeixinJSBridgeReady", vm.onBridgeReady(data));
        }
      } else {
        vm.onBridgeReady();
      }
    },
    //微信内置浏览器类，weChatParameter对象中的参数是3.步骤代码中从后端获取的数据  vm.weChatParameter.signType
    onBridgeReady() {
      var vm = this;
      // var timestamp = Math.round(vm.weChatParameter.timestamp).toString();
      var prepay_id = "prepay_id=" + vm.weChatParameter.prepay_id;
      // vm.weChatParameter.signType
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          debug: true,
          appId: vm.weChatParameter.appId, //公众号名称，由商户传入
          timeStamp: vm.weChatParameter.timestamp, //时间戳，自1970年以来的秒数
          nonceStr: vm.weChatParameter.nonceStr, //随机串
          package: prepay_id,
          signType: vm.weChatParameter.signType, //微信签名方式：
          paySign: vm.weChatParameter.paySign, //微信签名
          jsApiList: ["chooseWXPay"],
        },
        function (res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // alert("支付成功");
            vm.$message.success("支付成功");
            // vm.number = null;
            // vm.$router.go(-1);
            // vm.$router.push("/ordermangement");
            window.location.href = vm.baseURL + "/ordermangement";
          } else {
            this.$message.error("支付失败");
          }
        }
      );
    },
  },

  mounted() {
    this.getmemberlist();
    this.Getagreement();
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
    if (this.isMobile) {
      this.isWechat = this.is_weixn();
      if (this.isWechat) {
        this.getCodeApi();
      }
    }
  },
};
</script>


<style type="text/scss" lang="scss" scoped>
.payWechat {
  .flex {
    display: flex;
    justify-content: space-between;
    #wechatcode {
      width: 200px;
      height: 200px;
      padding: 15px;
      border-radius: 5px;
      border: 1px solid #bcbbbb;
    }

    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 40px;
      .money {
        color: #666666;
        font-size: 18px;
        width: auto;
        display: block;
        span {
          color: var(--main-color);
          font-size: 22px;
        }
      }
      .text {
        color: #999999;
        font-size: 14px;
        display: flex;
        img {
          width: 38px;
          height: 38px;
          margin-right: 14px;
        }
      }
    }
  }
}
.text {
  font-size: 16px;
}
.pc {
  .box-card {
    width: 1280px;
    margin: 1% auto;
    background-color: white;
    min-height: 45vh;
  }
}

.Mobile {
  // .box-card {
  padding: 10px 0;
  background-color: white;
  //   min-height: 45vh;
  .text.item {
    margin-left: 0;
    .user {
      margin: 0 15px;
    }
    .money {
      width: auto;
      margin: 0 15px;
      box-sizing: border-box;
      margin-top: 20px;
      li {
        width: 30%;
        .memberItem {
          width: 100%;
          min-height: 120px;
          border: 1px solid #dedede;
          .Item {
            margin: 30px 0px;
            font-size: 14px;
          }
          .displayLogo {
            height: 40px;
            .choiceLogo {
              border-bottom-right-radius: 8px;
              border-left: 40px solid transparent;
              border-bottom: 40px solid var(--main-color);
              .choiceIcon {
                width: 20px;
                height: 30px;
                margin-left: -22px;
                margin-top: 13px;
              }
            }
          }
        }
        .redBorder {
          border: 1px solid var(--main-color);
        }
      }
    }
    .power {
      display: none;
      padding: 0 15px;
      margin-top: 40px;
      padding-top: 15px;
      border-top: 1px solid #dfdfdf;
      .title {
        font-weight: 18px;
        margin: 10px 0;
        font-weight: bolder;
      }
      table {
        width: 100%;
        line-height: 40px;
        text-align: center;
        border: 1px solid #dedede;
        tr:first-child {
          td {
            background: #f9f9f9;
          }
        }
        tr {
          td:first-child {
            border-right: 1px solid #f9f9f9;
          }
          td {
            border: 0;
            width: 50%;
            border-bottom: 1px solid #dedede;
          }
        }
        tr:last-child {
          td {
            border-bottom: 0;
          }
        }
        tr:nth-child(2) {
          td:last-child {
            color: #3f7e35;
          }
        }
        .icon {
          font-size: 20px;
        }
        .success {
          color: #3f7e35;
        }
        .error {
          color: var(--main-color);
        }
        .el-icon-check,
        .el-icon-close {
          font-weight: bolder;
        }
      }
    }
    .redBorder {
      display: block;
    }
    .checkout {
      width: auto;
      padding: 0 15px;
      margin-top: 40px;
      box-sizing: border-box;
      border-top: 1px solid #dfdfdf;
      padding-top: 15px;
      > .title {
        font-weight: bolder;
      }
      .list {
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 18px 0px;
          font-size: 15px;
          color: #333333;
          font-weight: 400;
          margin: 0;
          .left img {
            width: 20px;
            object-fit: contain;
            height: 17px;
            vertical-align: middle;
            margin-right: 7px;
          }
          .right img {
            width: 17px;
            height: 17px;
          }
        }

        .item:not(:last-child) {
          border-bottom: 1px solid #dedede;
        }
        .doCheck {
          display: none;
        }
        .checkImg {
          display: block;
        }
        .choose {
          .doCheck {
            display: block;
          }
          .checkImg {
            display: none;
          }
        }
      }
    }
    .checkbox {
      width: auto;
      padding: 0 15px;
      margin-top: 50px;
    }
    .agree {
      width: auto;
      margin: 0 15px;
      margin-top: 20px;
      .agreebutton {
        width: 100%;
        height: 45px;
        line-height: 45px;
      }
    }
  }
  // }
}
.checkbox {
  font-size: 14px;
  font-weight: 400;
  color: #999;
  span {
    color: var(--main-color);
    cursor: pointer;
    font-size: 14px;
  }
}
.item {
  margin-bottom: 18px;
  margin-left: 5%;
}
.user {
  display: flex;
  .el-dropdown-link {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    .el-avatar {
      margin-right: 10px;
      box-shadow: 3px 2px 0 0 #e8cb90ff;
    }
  }
  .detail {
    margin: 5px 10px;
    font-size: 14px;
    .title {
      margin: 15% 0px;
      color: #a58858;
    }
    .title:first-child {
      font-size: 18px;
      font-weight: bolder;
    }
  }
}
.checkout {
  margin-top: 3%;
  width: 90%;
  font-size: 16px;
  .out {
    cursor: pointer;
    margin: 2% 2% 2% 0;
    width: 180px;
    background-color: #f5f5f5;
    border-radius: 10px;
    height: 45px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .outReally {
    color: var(--main-color);
    border: 1px solid var(--main-color);
    .choiceLogo {
      float: right;
      width: 0;
      height: 0;
      position: absolute;
      margin-top: 15px;
      margin-left: 150px;
      border-bottom-right-radius: 10px; /* 右下角圆角 */
      border-left: 30px solid transparent;
      border-bottom: 30px solid var(--main-color);
      border-bottom: 30px solid var(--main-color);
    }

    .choiceIcon {
      width: 10px;
      height: 10px;
      margin-left: -13px;
      margin-top: 13px;
      background-image: url("../assets/check.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
::v-deep {
  .el-checkbox__label {
    color: #999;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    color: #999;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: var(--main-color);
    border-color: var(--main-color);
  }
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: var(--main-color);
  }
  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: var(--main-color);
  }
}
.money {
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
  width: 90%;
  .memberItem {
    flex: 1;
    border: #666666 1px solid;
    min-height: 45.9vh;
    width: 300px;
    border-radius: 10px;
    position: relative;
    cursor: pointer; /* 鼠标变成手型 */
    overflow: hidden;
    .choiceLogo {
      float: right;
      width: 0;
      height: 0;
      border-bottom-right-radius: 8px; /* 右下角圆角 */
      border-left: 80px solid transparent;
      border-bottom: 80px solid var(--main-color);
    }

    .choiceIcon {
      width: 30px;
      height: 54px;
      margin-left: -40px;
      margin-top: 30px;
      background-image: url("../assets/check.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .Item {
      margin-top: 50px;
      width: 100%;
      text-align: center;
      font-size: 18px;
    }
    .span {
      margin: 15px;
    }
    .power {
      width: 80%;
      margin: 25px auto;
    }
  }

  .displayLogo {
    bottom: -1px;
    right: -1px;
    position: absolute;
    height: 80px;
    display: none;
  }
  .displayLogo1 {
    display: block;
  }
  .redBorder {
    border: var(--main-color) 2px solid;
  }
  li {
    list-style-type: none;
    .success {
      color: #3f7e35;
    }
    .error {
      color: var(--main-color);
    }
    .icon {
      font-size: 22px;
    }
    .el-icon-check,
    .el-icon-close {
      font-weight: bolder;
    }
  }
  .power {
    li:first-child {
      margin-bottom: 20px;
      margin-top: 15px;
      .success {
        font-weight: normal;
      }
    }
  }
}
.agree {
  margin-top: 3%;
  width: 90%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  .agreebutton {
    width: 500px;
    background-color: var(--main-color);
    color: white;
    font-size: 18px;
    height: 55px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
.paymentCon {
  max-height: 50vh;
  overflow: auto;
  padding-right: 13px;
  &::-webkit-scrollbar-thumb {
    border-radius: 1px;
    background-color: var(--main-color);
  }

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }
}
.dialog-footer {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  .agreebutton {
    width: 200px;
    background-color: var(--main-color);
    color: white;
    font-size: 18px;
    height: 35px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
</style>

