<template style="height: 100%">
  <div>
    <div v-if="isMobile===false">
      <div style="display: flex;flex-direction: column;min-height: 100vh">
        <div class="wrapper" style="flex: 1;">
          <Header></Header>
          <div style="display: flex;width: 1280px;margin:2px auto">
            <div class="talk_con" style="display: flex; flex-direction: column; align-items: center;">
              <div class="feedback-container" v-if="languageSelect==='zh'">
                <div style="margin:10px auto;font-size:20px;color:#333333;font-weight: bold">意见反馈</div>
              </div>
              <div class="feedback-container" v-if="languageSelect==='en'">
                <div style="margin:10px auto;font-size:20px;color:#333333;font-weight: bold">
                  Feedback
                </div>
              </div>
              <div class="form-container" style="flex: 1;margin-top: 20px" v-if="languageSelect==='zh'">
                <el-row :gutter="15">
                  <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="150px">
                    <el-col :span="16">
                      <el-form-item label="反馈内容" prop="feedbackContent">
                        <el-input v-model="formData.feedbackContent" type="textarea" placeholder="请输入反馈内容"
                                  :autosize="{minRows: 4, maxRows: 4}" class="inputStyle"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="36">
                      <el-form-item label="附件内容" prop="field102">
                        <el-upload
                            ref="field102" :file-list="field102fileList" :action="field102Action"
                            :before-upload="field102BeforeUpload"
                            class="inputStyle"
                            style="width: 500px"
                            drag
                            multiple>
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">请拖拽文件上传, 或<em>
                            点击上传</em></div>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="联系姓名" prop="userName">
                        <el-input class="custom-input" v-model="formData.userName" placeholder="请输入联系姓名"
                                  clearable :style="{width: '90%'}">
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="联系电话" prop="phoneNum">
                        <el-input class="custom-input" v-model="formData.phoneNum"
                                  placeholder="请务必输入手机号，后续将通过此号码与您联系"
                                  show-word-limit clearable prefix-icon='el-icon-mobile'
                                  :style="{width: '90%'}"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="联系邮箱" prop="email">
                        <el-input class="custom-input" v-model="formData.email" placeholder="请输入您的联系邮箱"
                                  clearable
                                  :style="{width: '90%'}">
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                    </el-col>
                    <div style="width: 800px; text-align:center;">
                      <el-button
                          type="primary"
                          style="font-size:16px;height:44px;background-color: #bd0022; border: none;border-radius: 32px;text-align: center; margin: 5px auto;width: 500px"
                          @click="submitForm">
                        提交
                      </el-button>
                    </div>

                  </el-form>
                </el-row>

              </div>
              <div class="form-container" style="flex: 1;margin-top: 20px" v-if="languageSelect==='en'">
                <el-row :gutter="15">
                  <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="150px">
                    <el-col :span="16">
                      <el-form-item label="Content" prop="feedbackContent">
                        <el-input v-model="formData.feedbackContent" type="textarea" placeholder="Please enter Feedback content"
                                  :autosize="{minRows: 4, maxRows: 4}" class="inputStyle"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="36">
                      <el-form-item label="Attachment" prop="field102">
                        <el-upload
                            ref="field102" :file-list="field102fileList" :action="field102Action"
                            :before-upload="field102BeforeUpload"
                            class="inputStyle"
                            style="width: 500px"
                            drag
                            multiple>
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">Drag files here, or<em>
                            Click to upload</em></div>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="Contact name" prop="userName">
                        <el-input class="custom-input" v-model="formData.userName"
                                  placeholder="Please enter contact name" clearable :style="{width: '90%'}">
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="Contact number" prop="phoneNum">
                        <el-input class="custom-input" v-model="formData.phoneNum"
                                  placeholder="Please be sure to enter your mobile phone number, we will contact you via this phone number later"
                                  show-word-limit clearable prefix-icon='el-icon-mobile'
                                  :style="{width: '90%'}"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="Contact email" prop="email">
                        <el-input class="custom-input" v-model="formData.email"
                                  placeholder="Please enter your contact email" clearable
                                  :style="{width: '90%'}">
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                    </el-col>
                    <div style="width: 800px; text-align:center;">
                      <el-button
                          type="primary"
                          style="font-size:16px;height:44px;background-color: #bd0022; border: none;border-radius: 32px;text-align: center; margin: 5px auto;width: 500px"
                          @click="submitForm">
                        Submit
                      </el-button>
                    </div>

                  </el-form>
                </el-row>

              </div>
            </div>
            <div class="talk_com">
              <div class="talk_com1" v-if="languageSelect==='zh'">
                <div style="font-size: 20px;margin-top: 10px">联系我们:</div>
                <div class="list">
                  <div v-if="contentType.phone">电话：{{ contentType.phone }}</div>
                  <div v-if="contentType.email">邮箱：{{ contentType.email }}</div>
                  <div v-if="contentType.address">
                    地址：{{ contentType.address }}
                  </div>
                </div>
              </div>
              <div class="talk_com1" v-if="languageSelect==='en'">
                <div style="font-size: 20px;margin-top: 10px">Connect us:</div>
                <div class="list">
                  <div v-if="contentType.phone">Phone：{{ contentType.phone }}</div>
                  <div v-if="contentType.email">E-mail：{{ contentType.email }}</div>
                  <div v-if="contentType.address">
                    Address： Address：No. 11 Yingbin South Road, Huairou District, Beijing
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>

      </div>
    </div>
    <div v-if="isMobile===true">
      <AiPop v-if="isMobile===true" @change="showDialog"></AiPop>
      <div style="display: flex;flex-direction: column;min-height: 100vh">
        <div class="wrapper1" style="flex: 1;">
          <Header></Header>
          <div class="talk_con" style="align-items: center;" v-if="languageSelect==='zh'">
            <div class="feedback-container">
              <div style="margin:10px auto;width:100%;text-align:center;font-size:20px;color:#333333;font-weight: bold">
                意见反馈
              </div>
            </div>
            <div class="form-container" style="flex: 1;margin-top: 20px">
              <el-row :gutter="15">
                <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
                  <el-col :span="16">
                    <el-form-item label="反馈内容" prop="feedbackContent">
                      <el-input v-model="formData.feedbackContent" type="textarea" placeholder="请输入反馈内容"
                                :autosize="{minRows: 4, maxRows: 4}" class="inputStyle"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="36">
                    <el-form-item label="附件内容" prop="field102">
                      <el-upload
                          ref="field102" :file-list="field102fileList" :action="field102Action"
                          :before-upload="field102BeforeUpload"
                          class="upload"
                          :style="{width:'650px'}"
                          drag
                          multiple>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="联系姓名" prop="userName">
                      <el-input class="custom-input1" v-model="formData.userName" placeholder="请输入联系姓名" clearable
                                :style="{width: '90%'}">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="联系电话" prop="mobile">
                      <el-input class="custom-input1" v-model="formData.phoneNum"
                                placeholder="请务必输入手机号，后续将通过该电话与您联系"
                                show-word-limit clearable prefix-icon='el-icon-mobile'
                                :style="{width: '90%'}"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="联系邮箱" prop="email">
                      <el-input class="custom-input1" v-model="formData.email" placeholder="请输入您的联系人邮箱"
                                clearable
                                :style="{width: '90%'}">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                  </el-col>
                  <div style="width: 100%; text-align:center;">
                    <el-button
                        type="primary"
                        style="font-size:16px;height:44px;background-color: #bd0022; border: none;border-radius: 32px;text-align: center; margin: 5px auto;width:250px"
                        @click="submitForm">
                      提交
                    </el-button>
                  </div>

                </el-form>
              </el-row>

            </div>
          </div>
          <div class="talk_con" style="align-items: center;" v-if="languageSelect==='en'">
            <div class="feedback-container">
              <div style="margin:10px auto;width:100%;text-align:center;font-size:20px;color:#333333;font-weight: bold">
                Feedback
              </div>
            </div>
            <div class="form-container" style="flex: 1;margin-top: 20px">
              <el-row :gutter="15">
                <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
                  <el-col :span="16">
                    <el-form-item label="Content" prop="feedbackContent">
                      <el-input v-model="formData.feedbackContent" type="textarea" placeholder="Please enter Feedback content"
                                :autosize="{minRows: 4, maxRows: 4}" class="inputStyle"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="36">
                    <el-form-item label="Attachment" prop="field102">
                      <el-upload
                          ref="field102" :file-list="field102fileList" :action="field102Action"
                          :before-upload="field102BeforeUpload"
                          class="upload"
                          :style="{width:'650px'}"
                          drag
                          multiple>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">Drag files here, or<em>
                          Click to upload</em></div>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="YourName" prop="userName">
                      <el-input class="custom-input1" v-model="formData.userName"
                                placeholder="Please enter contact name" clearable :style="{width: '90%'}">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="YourPhone" prop="mobile">
                      <el-input class="custom-input1" v-model="formData.phoneNum"
                                placeholder="Please be sure to enter your mobile phone number, we will contact you via this phone number later"
                                show-word-limit clearable prefix-icon='el-icon-mobile'
                                :style="{width: '90%'}"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="YourEmail" prop="email">
                      <el-input class="custom-input1" v-model="formData.email"
                                placeholder="Please enter your contact email" clearable
                                :style="{width: '90%'}">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                  </el-col>
                  <div style="width: 100%; text-align:center;">
                    <el-button
                        type="primary"
                        style="font-size:16px;height:44px;background-color: #bd0022; border: none;border-radius: 32px;text-align: center; margin: 5px auto;width:250px"
                        @click="submitForm">
                      Submit
                    </el-button>
                  </div>

                </el-form>
              </el-row>

            </div>
          </div>
        </div>

      </div>
      <BottomBanner v-if="isMobile===true"></BottomBanner>
    </div>

  </div>


</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {columnContact} from "@/api";
import BottomBanner from "@/components/BottomBanner.vue";
import AiPop from "@/components/AiPop.vue";
// import AiPop from "@/components/AiPop.vue";
// import BottomBanner from "@/components/BottomBanner.vue";

export default {
  name: "Feedback",
  components: {
    AiPop,
    BottomBanner,
    // AiPop,
    // BottomBanner,
    Header, Footer
  },
  props: [],
  data() {
    return {
      languageSelect: '',
      dialogVisible: false,
      isMobile: false,
      user: {
        userId: "",
        name: "",
      },
      contentType: {
        phone: "",
        email: "",
        address: "",
      },
      userId: localStorage.getItem("userId"),
      token: localStorage.getItem('access_token'),
      uploadedFileValue: null,
      formData: {
        feedbackContent: '',
        field102: null,
        userName: undefined,
        phoneNum: '',
        email: undefined,
        fileName: '',
      },
      rules: {
        feedbackContent: [{
          required: true,
          message: this.$i18n.locale == 'zh' ? '请输入反馈内容' : 'Please enter contact name',
          trigger: 'blur'
        }],
        field105: [],
        phoneNum: [{
          required: true,
          message: this.$i18n.locale == 'zh' ? '请务必输入手机号，后续将通过该电话与您联系' : 'Please be sure to enter your mobile phone number, we will contact you via this phone number later',
          trigger: 'blur'
        }, {
          pattern: /^1(3|4|5|7|8|9)\d{9}$/,
          message: this.$i18n.locale == 'zh' ? '手机号格式错误' : 'Phone number format error',
          trigger: 'blur'
        }],
        email: [{
          required: true,
          message: this.$i18n.locale == 'zh' ? '请输入正确的邮箱地址' : 'Please enter your contact email',
          trigger: 'blur'
        }, { type: 'email', message: this.$i18n.locale == 'zh' ? '邮箱地址格式错误' : 'Email address format error', trigger: ['blur', 'change'] }],
        field103: [],
      },
      field102Action: '/common/upload',
      field102fileList: [],
    }
  },
  computed: {},
  watch: {
    "$store.state.user"(newValue) {
      this.user = newValue;
    },
  },
  created() {
    if (localStorage.getItem('language')) {
      this.languageSelect = localStorage.getItem('language')
    } else {
      this.languageSelect = 'zh'
    }
    this.GetColumnContact()

  },
  mounted() {
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
  methods: {
    showDialog(type) {
      this.dialogVisible = type;
    },
    GetColumnContact() {
      columnContact()
          .then((res) => {
            this.contentType = res.data;
          })
          .catch(() => {
          });
    },

    // 表单提交
    submitForm() {
      this.$refs['elForm'].validate((valid) => {
          if (valid) {
            const formData = {
              userId: this.user.userId,
              feedbackContent: this.formData.feedbackContent,
              phoneNum: this.formData.phoneNum,
              email: this.formData.email,
              userName: this.formData.userName,
              filePath: this.uploadedFileValue ? this.uploadedFileValue.fileName : null,
            };
            this.$axios.post('/api/feedback/add', formData, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': this.token,
              }
            })
                .then(response => {
                  if (this.languageSelect === "zh") {
                    this.$message.success("提交成功，3秒后跳转到首页");
                  } else {
                    this.$message.success("Submit Success");
                  }

                  console.log('提交成功，', response);

                  // 在请求成功后延迟 5 秒跳转到 Home 页
                  setTimeout(() => {
                    // 使用路由进行页面跳转（假设路由对象为 this.$router）
                    this.$router.push('/'); // 你需要替换成你的 Home 页路径
                  }, 3000); // 延迟 5000 毫秒（即 5 秒）跳转
                })
                .catch(error => {
                  console.error('提交失败', error);
                  // 在请求失败时执行的操作
                });

          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },

    // 文件上传
    async fileUpload(file) {
      let isRightSize = file.size / 1024 / 1024 < 2
      if (!isRightSize) {
        if(this.$i18n.locale == 'zh'){
          this.$message.error('文件大小超过 2MB')
        }else{
          this.$message.error('File size exceeds 2MB')
        }
        
        return isRightSize
      }
      try {
        // 构建表单数据
        const formData = new FormData();
        formData.append('file', file);

        // 向后端发送文件到 /common/upload 接口
        const response = await this.$axios.post('/common/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        this.uploadedFileValue = response.data;
        this.field102fileList.push({
          name: response.data.newFileName,
          url: response.data.url
        })
        console.log(response.data); // 打印响应数据到控制台

        // 返回 true 允许上传，false 禁止上传
        return true;
      } catch (error) {
        console.error('上传失败:', error);
        return false;
      }
    },


    resetForm() {
      this.$refs['elForm'].resetFields()
    },
    field102BeforeUpload(file) {
      let isRightSize = file.size / 1024 / 1024 < 2
      if (!isRightSize) {
        if(this.$i18n.locale == 'zh'){
          this.$message.error('文件大小超过 2MB')
        }else{
          this.$message.error('File size exceeds 2MB')
        }
      }
      return isRightSize
    }
  }
}

</script>

<style>

.el-upload__tip {
  line-height: 1.2;
}

.talk_con {
  height: auto;
  width: 950px;
  margin: 20px 5px 20px 5px;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgb(100, 98, 98);
  background-color: #fff;
}

.talk_com1 {
  padding: 25px;
  background: linear-gradient(180deg, rgb(249, 167, 182, 0.5) 0%, rgba(255, 255, 255, 0) 95%);
  margin: 20px auto 20px 5px;
  height: 30vh;
  width: 300px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgb(100, 98, 98);

  .list {
    width: 300px;
    margin-top: 15px;
    line-height: 5vh;
  }
}

.container div {
  display: inline-block;
  vertical-align: top; /* 使两个div上对齐 */
}

.el-form-item__label {
  line-height: 44px;
  font-size: 16px;
  color: #333;
  text-align: right;
}

.inputStyle {
  width: 650px;
  height: 100%;
//border: 1px solid #CCCCCC; box-sizing: border-box; color: #333; font-size: 16px;
}

/* 在全局范围内使用 ::v-deep 选择器 */
::v-deep .inputStyle .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 10px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  color: #606266;
  background-color: #FFF;
  background-image: none;
  border: none;
  border-radius: 4px;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
}

.upload .el-upload-dragger {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 60vw;
  height: 180px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}

.el-upload-dragger {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 650px;
  height: 180px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}

/* 定义自定义类 custom-input 的边框样式 */
.custom-input .el-input__inner {
  /* margin-bottom: 15px; */
  width: 650px;
  border: 1px solid #d9d9d9 !important; /* 修改边框样式为红色实线边框，可根据需要修改颜色、宽度等属性 */
  /* 添加其他所需的边框样式属性 */
}

.el-input__icon {
  height: 0;
  width: 25px;
  text-align: center;
  transition: all .3s;
  line-height: 40px;
}

/* 定义自定义类 custom-input 的边框样式 */
.custom-input1 .el-input__inner {
  margin-bottom: 15px;
  width: 60vw;
  border: 1px solid #d9d9d9 !important; /* 修改边框样式为红色实线边框，可根据需要修改颜色、宽度等属性 */
  /* 添加其他所需的边框样式属性 */
}

.wrapper1 {
  .el-upload__tip {
    line-height: 1.2;
  }

  .talk_con {
    height: auto;
    width: 99vw;
    margin: 20px 5px 20px 5px;
    padding: 30px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgb(100, 98, 98);
    background-color: #fff;
  }

  .container div {
    display: inline-block;
    vertical-align: top; /* 使两个div上对齐 */
  }

  .el-form-item__label {
    line-height: 44px;
    font-size: 16px;
    color: #333;
    text-align: right;
  }

  .inputStyle {
    width: 60vw;
    height: 100%;
  //border: 1px solid #CCCCCC; box-sizing: border-box; color: #333; font-size: 16px;
  }

  /* 在全局范围内使用 ::v-deep 选择器 */

  ::v-deep .inputStyle .el-textarea__inner {
    display: block;
    resize: vertical;
    padding: 5px 10px;
    line-height: 1.5;
    box-sizing: border-box;
    width: 250px;
    font-size: inherit;
    color: #606266;
    background-color: #FFF;
    background-image: none;
    border: none;
    border-radius: 4px;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  }

  .el-upload-dragger {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    width: 250px !important;
    height: 180px;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
  }

  .el-input__icon {
    height: 0;
    width: 25px;
    text-align: center;
    transition: all .3s;
    line-height: 40px;
  }

}
</style>
